import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Dropdown } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import "../Component/layout.css";

export default function NabarCompo() {
  const location = useLocation();
  const navigate = useNavigate();
  const MyCartItems = useSelector((state) => state.cart || []);

  const pathName = location.pathname;
  let cartShow = true;
  if (pathName === "/servicedetails" || pathName === "/ViewCart") {
    cartShow = false;
  }

  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);

  const [city, setCity] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);

  useEffect(() => {
    getCity();
    getSubcategory();
  }, []);

  const getSubcategory = async () => {
    try {
      const res = await axios.get(
        `https://api.vijayhomesuperadmin.in/api/userapp/getappsubcat`
      );

      if (res.status === 200) {
        setCategoryData(res.data.subcategory);
      }
    } catch (err) {
      console.log(err, "Error while fetching subcategories");
    }
  };

  const getCity = async () => {
    try {
      const res = await axios.get(
        "https://api.vijayhomesuperadmin.in/api/master/getcity"
      );
      if (res.status === 200) {
        const sortedCities = res.data.mastercity.sort((a, b) =>
          a.city.localeCompare(b.city)
        );
        setCity(sortedCities);
      }
    } catch (err) {
      console.log(err, "Error while fetching cities");
    }
  };

  const handleRemove = () => {
    localStorage.removeItem("user");
    
  };

  return (
    <Navbar
      className="navbar-homecity"
      expand="lg"
      style={{ backgroundColor: "aliceblue",marginTop:"-15px" }}
      
    >
      <Container>
        <Navbar.Brand className="fnt rounded-lg brd p-1" href="/">
          <img
            src="https://vijayahomeservices.b-cdn.net/vhs-lgo.png"
            alt="Logo"
            width={40}
            height={40}
          />
          <span className="clrrdd boldt mx-2 poppins-medium">
            Vijay Home Services
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end menu">
          {userData ? (
            <Nav className=" p-0 px-2">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "darkred",
                    borderRadius: "50px",
                    border: "none",
                  }}
                  variant=""
                  id="dropdown-basic"
                >
                  <i
                    className="fa-solid fa-user"
                    style={{ fontSize: "15px", color: "white" }}
                  ></i>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    zIndex: 1050,
                    minWidth: "150px",
                    overflow: "visible",
                  }}
                >
                  <Dropdown.Item
                    className="dropdown-item poppins-regular"
                    style={{ fontSize: "12px" }}
                    href="/mybooking"
                  >
                    My Bookings
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleRemove}
                    className="poppins-regular dropdown-item"
                    style={{ fontSize: "12px" }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <span
                className="mx-2 poppins-medium"
                style={{ fontSize: "14px", marginTop: "7px" }}
              >
                {userData?.customerName}
              </span>
            </Nav>
          ) : (
            <Link
              className="mx-2"
              to="/homelogin"
              style={{ textDecoration: "none" }}
            >
              <div
                className="poppins-black mx-1"
                style={{ color: "darkred", fontSize: "15px" }}
              >
                Login / Signup
              </div>
            </Link>
          )}

          {cartShow && (
            <Nav className="ms-5">
              <a href="/cart" style={{ textDecoration: "none" }}>
                <i
                  className="fa-solid fa-cart-shopping mt-2"
                  style={{ fontSize: "17px", color: "black" }}
                ></i>
              </a>
              <div
                className="text-center poppins-black"
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "100%",
                  backgroundColor: "darkred",
                  color: "white",
                  fontSize: "11px",
                  paddingTop: "0px",
                  position: "absolute",
                  marginLeft: "14px",
                  marginTop: "-6px",
                }}
              >
                {MyCartItems.length > 0 ? MyCartItems.length : 0}
              </div>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
