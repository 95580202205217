import React from "react";
import vhslogo from "../../src/assets1/vhs-lgo.png";
import { Link } from "react-router-dom";

function Pmobileheader() {
  return (
    <div
      className="d-flex p-mobile-header"
      style={{
        backgroundColor: "aliceblue",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: 'center', // Add alignItems: 'center' here
      }}
    >
      <div className="d-flex align-items-center"> {/* Add align-items-center to the logo and text div */}
        <img
          src={vhslogo}
          alt="vhs logo"
          style={{ width: "40px", height: "40px" }}
        />
        <span className="poppins-black mx-3">Vijay Home Services</span>
      </div>
      <div className="d-flex align-items-center"> {/* Add align-items-center to the My Bookings div */}
        <Link
          to="/myorders"
          style={{
            textDecoration: "none",
            color: "darkred",
          }}
          className="poppins-black text-end ms-auto"
        >
          My Bookings
        </Link>
      </div>
    </div>
  );
}
export default Pmobileheader;