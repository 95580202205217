import React from "react";
import { Router, Routes, Route, Redirect, Navigate} from "react-router-dom";
import SEO from "./SEO.js"; // Import the SEO component....................
import Login from "./Component/Login/Login";
import Register from "./Component/Register/Register";
import ResetPassword from "./Component/ResetPassword/ResetPassword";
import ServicesView from "./Pages/ServicesView/ServicesView";
import ViewCart from "./Pages/ViewCart/ViewCart";
import Booking from "./Pages/Booking/Booking";
import BookingDetails from "./Pages/BookingDetail/BookingDetails";
import Servicedetails from "./Component/Servicedetails";
import Viewdetails from "./Component/Viewdetails";
import Cart from "./Component/Cart";
import Espage from "./Component/Espage";
import Cartbook from "./Component/Cartbook";
import Home from "./Component/Home";
import Summary from "./Component/Summary";
import Esuccess from "./Component/Esuccess";
import Success from "./Component/Success";
import Paymentgateway from "./Component/Paymentgateway";
import About from "./Component/About";
import Career from "./Component/Career";
import Paymentsuccess from "./Component/Paymentsuccess";
import Paymentfailure from "./Component/Paymentfailure";
import Mybooking from "./Component/Mybooking";
import Mainheader from "./Component/Mainheader";
import Completed from "./Component/Completed";
import Upcomingdetail from "./Component/Upcomingdetail";
import Enquirydetails from "./Component/Enquirydetails";
import Completeddetails from "./Component/Completeddetails";
import Homecity from "./Component/Homecity";
import Subcategory from "./Component/Subcategory";
import Serviceviewdetails from "./Component/Serviceviewdetails";
import Terms from "./Component/Terms";
import Privacy from "./Component/Privacy";
import Cancellation from "./Component/Cancellation";
import Contact from "./Component/Contact";
import Interiorcompany from "./Component/Interiorcompany";
import Categeries from "./Component/Categeries";
import Cartpetcleaning from "./Component/Carpetcleaning";
import Invest from "./Component/Invest";
import Registerus from "./Component/Registerus";
import Homelogin from "./Component/Homelogin";
import Paymentdemo from "./Component/Paymentdemo";
import Blog from "./Component/Blog";
import Blogdetails from "./Component/Blogdetails";
import Thankyou from "./Component/Thankyou";
import Packersmovershome from "./Component/Packersmovershome";
import Phome from "./Component/Phome";
import Pviewdetails from "./Component/Pviewdetails";
import PserviceDetails from "./Component/PserviceDetails";
import Pmsummary from "./Component/Pmsummary";
import Pmlogin from "./Component/Pmlogin";
import Pmorder from "./Component/Pmorder";
import Pmorderdetails from "./Component/Pmorderdetails";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import ApplianceSEO from './Component/appliance seo';
import LocationPage from './Component/LocationPage';
import BangalorePage from "./Component/BangalorePage";  
import { HelmetProvider } from 'react-helmet-async'; 
import { useState, useEffect } from 'react';
import  blogContentData from './Component/Blogdetails.js';
import GarbageRedirect from './GarbageRedirect.js'; 
import PaintingChargesPage from './Component/PaintingChargesPage.js';

const CityComponent = () => {
  const { cityName } = useParams(); 

  return (
    <>
      <SEO
        title={`Vijay Home Services in ${cityName}`}
        description={`Explore services in ${cityName}`}
        keywords={`${cityName}, services`}
        url={`/${cityName}`}
        image="path/to/image.jpg"
      />
      <Homecity />
    </>
  );
};

const getFirstWords = (text, wordLimit) => {
  const words = text.split(/\s+/); 
  return words.slice(0, wordLimit).join(" ");
};

const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const BlogDetails = () => {
  const { title } = useParams(); 
  const [blogContent, setBlogContent] = useState(null); 
  const formattedTitle = toTitleCase(title.replace(/-/g, ' '));

  useEffect(() => {
    
    const blogData = blogContentData[title];

    if (blogData) {
      console.log("Fetched blog data:", blogData);
      setBlogContent(blogData);
    } else {
      console.log("Blog data not found for title:", title); 
    }
  }, [title]);

  const blogDescriptions = {
    'vijay-home-appliance-repair-services': "In today's fast-paced world, household appliances are essential for maintaining comfort and efficiency in our daily lives.",
    'vijay-home-packers-and-movers-your-hasslefree-moving-partner': 'Moving to a new home can be an exciting experience—new beginnings, a fresh start, and the opportunity to create new memories.  ',
    'key-trends-shaping-the-packers-and-movers-industry-in-2024': 'In 2024, the packers and movers industry is rapidly adopting smart technology and the Internet of Things (IoT) to enhance operational efficiency.  ',
    'leading-technological-developments-in-the-logistics-sector': 'The logistics industry has experienced tremendous growth and transformation over the past decade, largely driven by advancements in technology.  ',
    'top-tips-for-finding-the-best-packers-and-movers-deals': "Relocating can be a daunting task, whether it's moving to a new city or just across town. ",
    'how-can-i-have-my-refrigerator-fixed-in-bangalore': "If you're looking to get your Refrigerator fixed in Bangalore, it’s important to follow a structured approach ",
    'get-professionals-to-repair-your-ac-quickly-in-bangalore': 'Before calling a professional, it helps to have a basic understanding of common AC issues. This allows you to explain the problem better ',
    'packers-and-movers-in-ahmedabad-vijay-home-services': 'Relocating can be both an exciting and challenging experience, with endless tasks and a hefty amount of packing and moving to manage. ',
    'cool-comfort-premier-ac-service-in-ahmedabad': 'In the hot and humid climate of Ahmedabad, air conditioners are essential for comfort and productivity. , ',
    'best-packers-and-movers-in-bhubaneshwar': "Bhubaneshwar, the capital of Odisha, is a rapidly growing city that has become a hub for education, business, and tourism.  ",
    'best-appliance-repair-services-in-bangalore': 'Bangalore, the IT hub of India, is known for its fast-paced lifestyle and constant demand for reliable home services. With the growing population, the need for efficient appliance repair services has increased. ',
    'reliable-and-affordable-relocation-services-in-gurugram': 'Relocating can be an overwhelming experience, filled with both logistical and emotional challenges.  ',
    'why-regular-washing-machine-maintenance-service-is-crucial': 'Regular maintenance of your washing machine is essential for its longevity, efficiency, and overall performance. ',
    'best-time-to-relocate-an-intracity-and-intercity-relocation-tips':'Relocating, whether within the city or to a new one, involves meticulous planning and preparation.  ',
    'safe-house-relocation-how-to-keep-your-electronics-safe': 'Relocating a home can be an exciting experience, but it comes with its fair share of challenges. One of the biggest concerns during any move is ensuring the safety of your electronics. ',
    'expert-geyser-repair-services-in-bangalore-by-vijay-home-service':'A geyser is an essential appliance in every home, especially during the colder months or early mornings. Nothing feels better than a hot shower to start your day or relax after a long one.',
    'bring-back-life-to-your-clothes-with-washing-machine-repair-services':'Washing machines have become indispensable in our daily lives, making laundry a hassle-free task. However, when these essential appliances break down, it can disrupt your entire routine.',
    'why-you-should-hire-vijay-home-services-in-delhi-for-your-relocation': 'Choosing a provider that understands the complexities involved in Delhi, with its myriads of neighbourhoods and difficult traffic, is essential. ',
    'top-pest-control-services-vijay-home-services-for-a-pestfree-home':'Looking for reliable pest control services? Vijay Home Services ensures your home remains pest-free with expert solutions across various regions.',
    'top-painting-services-vijay-home-services-for-a-beautiful-home':'Looking for professional painting services for your home? Vijay Home Services offers expert house painting services to enhance the beauty of your home.',
    'effective-cockroach-control-a-guide-by-vijay-home-services':'Cockroach infestations are a common problem in Indian households. These resilient pests not only invade your living spaces but also pose significant health risks.',
    'say-goodbye-to-sleepless-nights-bed-bug-removal-with-vijay-home-services':'Bed bugs may not pose a direct health threat in most cases, but their presence can lead to frustration, irritation, and sleepless nights.',
    '5-signs-that-you-need-to-seek-professional-home-appliance-repair-service':'Home appliance is an important part of daily life, it aids to accomplish the home works so easy. However, like any machine, they need maintenance and at times repairs. ',
    'how-home-appliance-repair-can-help-you-save-money-and-trouble':'Home appliances have become increasingly necessary in everyday life, they make household chores much easier to tackle, greatly enhancing our quality of living.',
    'quick-impact-professional-touch-relocation-services-without-hassle':'Shifting to a new home or office could be an exciting milestone, but the process of moving comes with a lot of stress, confusion, and chaos. ',
    'fast-and-efficient-service-patnas-trusted-ac-repair-experts':'In a city like Patna, where the temperature soars in scorching summer months, a well-functioning air-conditioner is more than a luxury, it is a necessity.',
    'why-regular-cleaning-services-are-essential-for-a-healthy-home-deep-cleaning-experts':'Discover the health benefits of regular deep cleaning, bathroom cleaning, and kitchen cleaning. Learn how professional cleaning services reduce allergens, prevent mold, and ensure a bacteria-free home for a healthier lifestyle.',
    'top-10-benefits-of-hiring-a-professional-cleaning-service-for-your-home-and-office':'Discover the top 10 reasons to hire a professional cleaning service. From saving time to improving air quality, learn how expert cleaners can transform your home or office into a spotless and healthy space. ',
    '10-common-cleaning-mistakes-how-professional-services-can-fix-them-expert-tips':'Discover 10 common cleaning mistakes that can harm your home and how professional services can resolve them. From deep cleaning to proper sanitization, learn effective solutions for a spotless and healthy home. Contact Vijay Home Services today! ',
    'deep-cleaning-vs-regular-cleaning-key-differences-why-both-matter':'Discover the essential differences between deep cleaning and regular cleaning. Learn why both are crucial for maintaining a healthy, hygienic space and how to balance them effectively.',
    'the-ultimate-checklist-for-deep-cleaning-your-home-like-a-pro':'Transform your home with this ultimate deep cleaning checklist! Step-by-step guidance to tackle every room or know what to expect from professional cleaning services.',
    'how-to-plan-your-deliveries-with-vijay-home-services-bangalore':'Plan is one of the efficiently delivery tools for business holders and also, we play an essential role to secure and on-time delivery of goods.',
    'how-often-should-you-schedule-professional-cleaning-a-roombyroom-guide':'Wondering how often to schedule professional cleaning? This room-by-room guide helps you determine the ideal cleaning frequency for your home or office, ensuring a healthy and spotless space.',
    'ecofriendly-professional-cleaning-services-benefits-for-your-home-and-health':'Discover the benefits of eco-friendly professional cleaning services! Learn how green cleaning products and sustainable practices protect your health and the environment while keeping your home spotless.',
    'simplify-your-move-roombyroom-checklist-for-hasslefree-packing':'Relocating to a new home can feel overwhelming, but with proper planning and a clear checklist, the process becomes far less daunting. Vijay Home Services is here to guide you through this transition with a comprehensive room-by-room packing checklist designed to simplify your move.',
    'cut-costs-carbon-best-practices-for-energyefficient-air-conditioning':`In today's world, air conditioning can be considered a necessity — and that is especially the case in countries with scorching weather.`,
    'why-professional-pest-control-is-better-than-diy-5-expert-benefits':'Discover 5 key reasons why professional pest control is better than DIY. Learn how expert services offer long-term solutions, safety, and effectiveness to keep your home pest-free.',
    'the-ultimate-guide-to-professional-termite-control-signs-treatment-prevention':'Learn how to spot signs of a termite infestation, explore professional treatment options, and discover effective prevention methods. Protect your home from costly termite damage with expert solutions.',
    'affordable-shipping-container-services-in-ahmedabad':'Efficient logistics and cost-effective transportation are vital for your business and personal needs.',
    'choosing-the-best-appliances-repair-services-in-bangalore':'Importance of Truckload Transportation: Truckload transportation plays a crucial role in the global supply chain, transitioning goods from manufacturers to the end consumer.',
    'effective-pest-control-services-in-kochi-your-ultimate-guide-to-a-pestfree-home':'Discover the best pest control services in Kochi. Learn how to keep your home pest-free with expert tips, professional treatments, and prevention strategies.',
    'benefits-of-professional-refrigerator-repair-services-in-lucknow':'Refrigerators have become a necessity in every household as they preserve the freshness of food and drinks.',
    'say-goodbye-to-laundry-woes-top-washing-machine-repair-services-in-noida':'A washing machine is one of those devices which is a necessity in our daily life and we use that a fair bit.',
    '5-signs-you-should-immediately-call-a-professional-for-geyser-repair':'Geysers are perhaps one of the most useful equipment we utilize at home, particularly in winters.',
    'top-cleaning-services-in-kochi-home-deep-cleaning-experts':'Discover top cleaning services in Kochi for spotless homes and offices. Find expert house cleaning, deep cleaning, and home cleaning services in Kochi tailored to your needs.',
    'revolutionizing-truckload-transportation-trends-shaping-the-future':'Importance of Truckload Transportation: Truckload transportation plays a crucial role in the global supply chain, transitioning goods from manufacturers to the end consumer.',
    'top-5-best-pest-control-services-in-visakhapatnam-in-high-demand':'Discover the top 5 best pest control services in Visakhapatnam that are in high demand. From eco-friendly solutions to rodent and termite control, find the best pest management services to protect your home and office.',
    'top-5-pest-control-services-in-patna-safe-affordable-solutions':'Looking for effective pest control in Patna? Discover the top 5 pest control services offering safe, eco-friendly, and affordable solutions for your home and office. Keep pests at bay today!',
    'logistic-innovation-the-transport-industrys-efficiency-driver':'Vijay Home Services revolutionising the logistics solutions with industry-proven practices to meet modern-day challenges. Through this article, we talk about the significance of logistic innovation in the transport business',
    'pros-and-cons-of-packers-and-movers-is-it-worth-the-investment':'Hiring Professional Packers and Movers hiring professional packers and movers saves you from the mess of planning and execution of the move. We can all agree that moving is one of the most stressful processes out there.',
    'professional-home-appliance-repairs-in-faridabad':'Vijay Home Appliance Services is a reputed name in Faridabad that offers fast, reliable, and affordable services for all types of home appliances. While this is a quick guide, here is a detailed overview of their offerings and why they are the appliance repair service of choice in Faridabad.',
    'refrigerator-repair-services-in-ahmedabad-near-you':'We deliver the best refrigerator repair services across Ahmedabad based on the requirement of the customer. But what makes them the best refrigerator repair service in Ahmedabad. Call for details.',
    'top-tips-to-choose-the-best-painting-company-expert-guide-for-hiring-painters':'Discover essential tips for selecting the best painting company for your home or business. Learn about the importance of reviews, brand reputation, experience, and more to ensure quality results.',
    'top-10-interior-painting-trends-for-2024-transform-your-home-today':'Discover the top 10 interior painting trends for 2024, from earthy tones and jewel shades to textured walls and eco-friendly paints. Revamp your space with these ideas, and explore professional services like interior painting, texture painting, and wood polish for a flawless finish.',
    'local-transport-business-in-faridabad-how-to-make-rally-efficient':'Faridabad, one of the most active industrial townships situated in National Capital Region (NCR) India, is a vigour city where businesses are based in an ecosystem of trades and connectivity',
    'vijay-home-services-your-growth-partner-in-delhincr-you-can-trust':'The growing business and startup ecosystem of Delhi-NCR is a hub of efficiency, support services and strategic partnerships that are essential in maintaining a trajectory of growth and success',
    'affordable-and-efficient-packers-and-movers-in-ghaziabad':'Packers and movers in Ghaziabad have been genuine to offer services above any scope of competition within itself and are comprehended as clear contributors to the packing industry. Call at +91-8453748478 for more details.',
    'top-packers-and-movers-in-hyderabad-call-918453748478':'Vijay Home Services being the best packers and movers in Hyderabad and an overview of different services that professional movers provide within the city, so you can come in contact with the best packing and moving companies such as Vijay Home Services.',
  };

  const blogDescription = blogDescriptions[title]
  return (
    <>
      <SEO
        title={`${formattedTitle}`}  
        description={blogDescription} 
        keywords={`blog, ${formattedTitle}, article`}
        url={`/blogdetails/${title}`}
        image="path/to/image.jpg"
      />
      <Blogdetails />
    </>
  );
};

function App() {

  return (
    <HelmetProvider>
      <>
      <GarbageRedirect /> 
      <Routes>
      <Route
        path="/"
        element={<>
          <SEO
            title="Vijay Home Services - Experience top-notch services"
            description="Experience top-notch home services cleaning, painting, pest control,etc. Lowest Price in Market | ISO Certified Company. Trained Professionals | No Sub Contract. 100% Satisfaction or FREE Rework*."
            keywords="Bathroom cleaning services in Bangalore, Bathroom cleaning services in Mumbai,services Bangalore, packers and movers Bangalore, moving services Bangalore, house cleaning, Packers and Movers Service, Painting Services in Bangalore, Painting Services, Pest Control Services, Best cleaning services in bangalore."
            url="/"
            image="path/to/image.jpg" />
          <Home />
        </>} />
      <Route path="/:cityName" element={<CityComponent />} /> 
      <Route
        path="/:cityName"
        element={<>
          <SEO
            title="Home City"
            description="Explore services in your city"
            keywords="home services"
            url={`/$cityName}`}/>
          <Homecity />
        </>} />

      <Route path="/:cityName" element={<Packersmovershome />} />
      <Route
        path="login"
        element={<>
          <SEO
            title="Login"
            description="Login to your account"
            url="/login" />
          <Login />
        </>} />
      <Route
        path="register"
        element={<>
          <SEO
            title="Register"
            description="Create a new account"
            url="/register" />
          <Register />
        </>} />
      <Route
        path="resetpassword"
        element={<>
          <SEO
            title="Reset Password"
            description="Reset your password"
            keywords="reset, password"
            url="/resetpassword" />
          <ResetPassword />
        </>} />
      <Route
        path="servicesview"
        element={<>
          <SEO
            title="Services View"
            description="View our services"
            url="/servicesview"/>
          <ServicesView />
        </>} />
      <Route
        path="viewcart"
        element={<>
          <SEO
            title="View Cart"
            description="Check your cart"
            url="/viewcart"/>
          <ViewCart />
        </>} />
      <Route
        path="booking"
        element={<>
          <SEO
            title="Booking"
            description="Book a service"
            url="/booking"/>
          <Booking />
        </>} />
      <Route
        path="bookingdetails"
        element={<>
          <SEO
            title="Booking Details"
            description="Details of your booking"
            url="/bookingdetails" />
          <BookingDetails />
        </>} />

        <Route path="/refrigerator-repairing" element={<Navigate to="/services/refrigerator-repairing-in-bangalore" replace />} />
        <Route path="/washing-machine-repairing" element={<Navigate to="/services/washing-machine-repairing-in-bangalore" replace />} />
        <Route path="/ac-repairing" element={<Navigate to="/services/split-ac-service-in-bangalore" replace />} />
        <Route path="/split-ac-service" element={<Navigate to="/services/split-ac-service-in-bangalore" replace />} />
        <Route path="/window-ac-service" element={<Navigate to="/services/window-ac-service-in-bangalore" replace />} />

      <Route
        path="service/:service"
        element={<>
          <SEO
            title="Service Details"
            description="Details of the selected service"
            url="/service/:service" />
          <Servicedetails />
        </>} />
      <Route
        path="viewdetails"
        element={<>
          <SEO
            title="View Details"
            description="Detailed view of the service"
            url="/viewdetails"/>
          <Viewdetails />
        </>} />
      <Route
        path="cart"
        element={<>
          <SEO
            title="Cart"
            description="Your shopping cart"
            url="/cart"/>
          <Cart />
        </>} />
      <Route
        path="Espage"
        element={<>
          <SEO
            title="Cartbook"
            description="E-service page"
            url="/Espage" />
          <Espage />
        </>} />
      <Route
        path="cartbook"
        element={<>
          <SEO
            title="Cart Book"
            description="Book from your cart"
            url="/cartbook" />
          <Cartbook />
        </>} />
      <Route
        path="summary"
        element={<>
          <SEO
            title="Summary"
            description="Order summary"
            url="/summary"/>
          <Summary />
        </>} />
      <Route
        path="Esuccess"
        element={<>
          <SEO
            title="E-Success"
            description="Success page for E-service"
            url="/Esuccess" />
          <Esuccess />
        </>} />
      <Route
        path="success"
        element={<>
          <SEO
            title="Success"
            description="Order successful"
            url="/success"/>
          <Success />
        </>} />
      <Route
        path="/p"
        element={<>
          <SEO
            title="Payment Gateway"
            description="Proceed with payment"
            url="/p"/>
          <Paymentgateway />
        </>} />
      <Route
        path="/about-us"
        element={<>
          <SEO
            title="About Us"
            description="Learn more about us"
            url="/about-us"/>
          <About />
        </>} />
      <Route
        path="/career"
        element={<>
          <SEO
            title="Career"
            description="Join our team"
            url="/career" />
          <Career />
        </>} />
      <Route
        path="/Paymentsuccess"
        element={<>
          <SEO
            title="Payment Success"
            description="Your payment was successful"
            url="/Paymentsuccess" />
          <Paymentsuccess />
        </>} />
      <Route
        path="/Paymentfailure"
        element={<>
          <SEO
            title="Payment Failure"
            description="There was a problem with your payment"
            url="/Paymentfailure" />
          <Paymentfailure />
        </>} />
      <Route
        path="/mybooking"
        element={<>
          <SEO
            title="My Bookings"
            description="Your bookings"
            url="/mybooking"/>
          <Mybooking />
        </>} />
      <Route
        path="/mainheader"
        element={<>
          <SEO
            title="Main Header"
            description="Main header content"
            url="/mainheader"/>
          <Mainheader />
        </>} />
      <Route
        path="/completed"
        element={<>
          <SEO
            title="Completed"
            description="Your completed services"
            url="/completed" />
          <Completed />
        </>} />
      <Route
        path="/upcomingdetail"
        element={<>
          <SEO
            title="Upcoming Details"
            description="Details of upcoming services"
            url="/upcomingdetail"/>
          <Upcomingdetail />
        </>} />
      <Route
        path="/enquirydetails"
        element={<>
          <SEO
            title="Enquiry Details"
            description="Details of your enquiry"
            url="/enquirydetails" />
          <Enquirydetails />
        </>} />
      <Route
        path="/completeddetails"
        element={<>
          <SEO
            title="Completed Details"
            description="Details of completed services"
            url="/completeddetails" />
          <Completeddetails />
        </>} />
      <Route
        path="/services/:subcategory"
        element={<>
         
          <Subcategory />
        </>} />
      <Route
        path="/serviceview/:ServiceName"
        element={<>
          <SEO
            title="Service View"
            description="View the service"
            url="/serviceview/:ServiceName"/>
          <Serviceviewdetails />
        </>} />
      <Route
        path="/terms-and-condition"
        element={<>
          <SEO
            title="Terms and Conditions"
            description="Review our terms"
            url="/terms-and-condition" />
          <Terms />
        </>} />
      <Route
        path="/privacy-policy"
        element={<>
          <SEO
            title="Privacy Policy"
            description="Review our privacy policy"
            url="/privacy-policy" />
          <Privacy />
        </>} />
      <Route
        path="/refund-and-cancellation"
        element={<>
          <SEO
            title="Refund and Cancellation"
            description="Review our refund and cancellation policy"
            url="/refund-and-cancellation"/>
          <Cancellation />
        </>} />
      <Route
        path="/contact"
        element={<>
          <SEO
            title="Contact Us"
            description="Get in touch with us"
            url="/contact"/>
          <Contact />
        </>} />
      <Route
        path="/interiorcompany"
        element={<>
          <SEO
            title="Interior Company"
            description="Explore our interior design services"
            keywords="interior, painting, cleaning, home services, interior company"
            url="/interiorcompany" />
          <Interiorcompany />
        </>} />
      <Route
        path="/categories"
        element={<>
          <SEO
            title="Categories"
            description="Explore our categories"
            url="/categories" />
          <Categeries />
        </>} />
      <Route
        path="/carpetcleaning"
        element={<>
          <SEO
            title="Carpet Cleaning"
            description="Explore our carpet cleaning services"
            keywords="carpet cleaning, best carpet cleaning"
            url="/carpetcleaning" />
          <Cartpetcleaning />
        </>} />
      <Route
        path="/invest"
        element={<>
          <SEO
            title="Invest"
            description="Invest in our services"
            url="/invest" />
          <Invest />
        </>} />
      <Route
        path="/registerus"
        element={<>
          <SEO
            title="Register Us"
            description="Register for our services"
            url="/registerus" />
          <Registerus />
        </>} />
      <Route
        path="/homelogin"
        element={<>
          <SEO
            title="Login"
            description="Login to your home account"
            url="/homelogin" />
          <Homelogin />
        </>} />
      <Route
        path="/paymentdemo"
        element={<>
          <SEO
            title="Payment Demo"
            description="Demo of our payment process"
            url="/paymentdemo" />
          <Paymentdemo />
        </>} />
      <Route
         path="/blog"
         element={
           <>
             <SEO
               title="Blog"
               description="Read our latest blogs"
               url="/blog"
             />
             <Blog />
           </>
         }
       />
       <Route path="/blogdetails/:title" element={<BlogDetails />} />
      <Route
        path="/thankyou"
        element={<>
          <SEO
            title="Thank You"
            description="Thank you for your response"
            url="/thankyou" />
          <Thankyou />
        </>} />
      <Route
  path="/packers-movers"
  element={<Navigate to="/packers-movers/bangalore" replace />}
/>
<Route 
  path="services/packers-&-movers-in-bangalore" 
  element={<Navigate to="/packers-movers/bangalore" replace />} 
/>\
<Route path="services/packers-&-movers-in-ahmedabad" element={<Navigate to="/packers-movers/ahmedabad" replace />} />
  <Route path="services/packers-&-movers-in-bhubaneswar" element={<Navigate to="/packers-movers/bhubaneswar" replace />} />
  <Route path="services/packers-&-movers-in-chennai" element={<Navigate to="/packers-movers/chennai" replace />} />
  <Route path="services/packers-&-movers-in-delhi" element={<Navigate to="/packers-movers/delhi" replace />} />
  <Route path="services/packers-&-movers-in-faridabad" element={<Navigate to="/packers-movers/faridabad" replace />} />
  <Route path="services/packers-&-movers-in-gaziabad" element={<Navigate to="/packers-movers/gaziabad" replace />} />
  <Route path="services/packers-&-movers-in-hyderabad" element={<Navigate to="/packers-movers/hyderabad" replace />} />
  <Route path="services/packers-&-movers-in-kochi" element={<Navigate to="/packers-movers/kochi" replace />} />
  <Route path="services/packers-&-movers-in-kolkata" element={<Navigate to="/packers-movers/kolkata" replace />} />
  <Route path="services/packers-&-movers-in-lucknow" element={<Navigate to="/packers-movers/lucknow" replace />} />
  <Route path="services/packers-&-movers-in-mumbai" element={<Navigate to="/packers-movers/mumbai" replace />} />
  <Route path="services/packers-&-movers-in-mysore" element={<Navigate to="/packers-movers/mysore" replace />} />
  <Route path="services/packers-&-movers-in-delhi-ncr" element={<Navigate to="/packers-movers/delhi-ncr" replace />} />
  <Route path="services/packers-&-movers-in-noida" element={<Navigate to="/packers-movers/noida" replace />} />
  <Route path="services/packers-&-movers-in-patna" element={<Navigate to="/packers-movers/patna" replace />} />
  <Route path="services/packers-&-movers-in-pune" element={<Navigate to="/packers-movers/pune" replace />} />
  <Route path="services/packers-&-movers-in-surat" element={<Navigate to="/packers-movers/surat" replace />} />
  <Route path="services/packers-&-movers-in-vadodara" element={<Navigate to="/packers-movers/vadodara" replace />} />
  <Route path="services/packers-&-movers-in-vizag" element={<Navigate to="/packers-movers/vizag" replace />} />
      <Route
        path="/packers-movers/:city"
        element={<>
          <SEO
            title="Packers and Movers"
            description="Best Packers and Movers Services"
            keywords="packers, movers, relocation, shifting" />
          <Packersmovershome />
        </>} />
      <Route
        path="/packers-movers-services-details"
        element={<>
          <SEO
            title="Packers and Movers Services"
            description="Details of Packers and Movers services"
            keywords="packers services, moving services, relocation" />
          <Phome />
        </>} />
      <Route path="/packers-movers-Viewdetails" element={<Pviewdetails />} />
      <Route
        path="/packers-movers-servicedetails"
        element={<PserviceDetails />} />
      <Route path="/packers-movers-summary" element={<Pmsummary />} />
      <Route path="/pmlogin" element={<Pmlogin />} />
      <Route path="/myorders" element={<Pmorder />} />
      <Route path="/pmorderdetails_page" element={<Pmorderdetails />} />

     
      <Route path="/" element={<Home />} />
      <Route path="/locations/:locationId" element={<LocationPage />} />
        
        <Route 
          path="sevices/painting-charges-in-:cityName" 
          element={<PaintingChargesPage />} 
        />
           
        <Route path="/:cityName" element={<BangalorePage />} />
    <Route path="/services/:serviceSlug" component={ApplianceSEO} /></Routes>
      </>
    </HelmetProvider>
  
  );
  
}

export default App;

