import localities from './localities.json';

const seoTemplates = {
    'bathroom-cleaning': {
        title: (city, locality) => `Top Bathroom Cleaning Services in ${locality ? `${locality}, ` : ''}${city} | Vijay Home Services`,
        description: (city, locality) => `Get Professional Bathroom Cleaning Services in ${locality ? `${locality}, ` : ''}${city} for just ₹449. Call us on 8453748478 to get Affordable, Top-Rated Toilet Deep Cleaning for spotless, hygienic Bathrooms.`,
        keywords: (city, locality) => `bathroom cleaning services ${locality ? `${locality}, ` : ''}${city}, bathroom deep cleaning services ${locality ? `${locality}, ` : ''}${city}, toilet cleaning services ${locality ? `${locality}, ` : ''}${city}, toilet cleaning services near me, bathroom sanitization services ${locality ? `${locality}, ` : ''}${city}, bathroom cleaning near me, best bathroom cleaning near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for bathroom cleaning`,
    },
    'kitchen-cleaning': {
        title: (city, locality) => `Top Kitchen Cleaning Services in ${locality ? `${locality}, ` : ''}${city}| Kitchen Deep ${city}`,
        description: (city, locality) => `Get the Best Kitchen Cleaning Services in ${locality ? `${locality}, ` : ''}${city}! Our Professional Kitchen Cleaners remove grease, stains, and dirt at affordable rates across ${city}.Call us on 8453748478 !`,
        keywords: (city, locality) => `kitchen cleaning services ${locality ? `${locality}, ` : ''}${city}, kitchen cleaning near me, best kitchen cleaning services near me, professional kitchen cleaners ${locality ? `${locality}, ` : ''}${city}, affordable kitchen cleaning ${locality ? `${locality}, ` : ''}${city}, kitchen deep cleaning ${locality ? `${locality}, ` : ''}${city}, kitchen cleaning ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for kitchen cleaniing`,
    },
    'sofa-cleaning': {
        title: (city, locality) => `Top Sofa Cleaning Services in ${locality ? `${locality}, ` : ''}${city} | Vijay Home Services`,
        description: (city, locality) => `Book Professional Sofa Cleaning Services in ${locality ? `${locality}, ` : ''}${city} with Vijay Home Services, your expert for affordable and reliable sofa cleaning solutions in ${city} City.Call us on 8453748478 !`,
        keywords: (city, locality) => `sofa cleaning services ${locality ? `${locality}, ` : ''}${city}, best sofa cleaning service near me, sofa cleaning near me, professional sofa cleaners ${locality ? `${locality}, ` : ''}${city}, affordable sofa cleaning ${locality ? `${locality}, ` : ''}${city}, deep cleaning sofa ${locality ? `${locality}, ` : ''}${city}, upholstery cleaning services ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for sofa cleaning`,
    },
    'vacant-home-deep-cleaning': {
        title: (city, locality) => `Empty House Cleaning in ${locality ? `${locality}, ` : ''}${city} | Vacant Home Cleaning Services`,
        description: (city, locality) => `Call us on 8453748478 to book Professional Vacant Home Cleaning in ${locality ? `${locality}, ` : ''}${city}. Our Empty House Cleaning Services ensure spotless, ready-to-move homes. Affordable and hassle-free solutions.`,
        keywords: (city, locality) => `vacant home cleaning services ${locality ? `${locality}, ` : ''}${city}, vacant home cleaning services near me, empty house cleaning ${locality ? `${locality}, ` : ''}${city}, post-renovation cleaning ${locality ? `${locality}, ` : ''}${city}, move-in move-out cleaning ${locality ? `${locality}, ` : ''}${city}, professional home cleaning ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for vacant home cleaning`,
    },
    'deep-cleaning': {
        title: (city, locality) => `Top Deep Cleaning Services in ${locality ? `${locality}, ` : ''}${city} | Home Deep Cleaning ${city}`,
        description: (city, locality) => `Get the Best Deep Home Cleaning Services in ${locality ? `${locality}, ` : ''}${city}. Call us on 8453748478 to get affordable Deep House Cleaning to keep your space spotless. Most Trusted Deep Cleaning Services in ${city}.`,
        keywords: (city, locality) => `home deep cleaning ${locality ? `${locality}, ` : ''}${city}, deep cleaning services near me, deep cleaning services ${locality ? `${locality}, ` : ''}${city}, house deep cleaning ${locality ? `${locality}, ` : ''}${city}, deep cleaning services ${locality ? `${locality}, ` : ''}${city}, house cleaning services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for deep cleaning`,
    },
    'occupied-home-deep-cleaning': {
        title: (city, locality) => `Top Home Cleaning in ${locality ? `${locality}, ` : ''}${city} | Best House Cleaning Services`,
        description: (city, locality) => `Get the Top Home Cleaning in ${locality ? `${locality}, ` : ''}${city}. Our professional Home Cleaning Services ensure spotless, sanitized spaces at affordable rates. Best Cleaning Services ${city}.Call us on 8453748478 !`,
        keywords: (city, locality) => `cleaning services ${locality ? `${locality}, ` : ''}${city}, home cleaning ${locality ? `${locality}, ` : ''}${city}, house cleaning ${locality ? `${locality}, ` : ''}${city}, deep cleaning services ${locality ? `${locality}, ` : ''}${city}, deep cleaning ${locality ? `${locality}, ` : ''}${city}, occupied home deep cleaning near me, occupied home cleaning services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for occupied home deep cleaning`,
    },
    'after-interior-deep-cleaning': {
        title: (city, locality) => `After Interior Home Cleaning ${locality ? `${locality}, ` : ''}${city} | Vijay Home Services`,
        description: (city, locality) => `Get Professional After Interior Home Cleaning in ${locality ? `${locality}, ` : ''}${city}. Our Post Interior Cleaning Service ensures dust-free, spotless spaces after renovations across ${city}.Call us on 8453748478 !`,
        keywords: (city, locality) => `post renovation cleaning ${locality ? `${locality}, ` : ''}${city}, interior cleaning services ${locality ? `${locality}, ` : ''}${city}, deep cleaning after renovation ${locality ? `${locality}, ` : ''}${city}, affordable interior cleaning ${locality ? `${locality}, ` : ''}${city}, professional home cleaning ${locality ? `${locality}, ` : ''}${city}, interior cleaning services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for after interior home cleaning`,
    },
    'office-cleaning': {
        title: (city, locality) => `Top Office Cleaning Services in ${locality ? `${locality}, ` : ''}${city} | Vijay Home Services`,
        description: (city, locality) => `Get Top Office Cleaning Services in ${locality ? `${locality}, ` : ''}${city}. Our Professional Office Cleaning team ensures a clean and healthy environment for both your staff and clients in ${city}. Call us on 8453748478 ! `,
        keywords: (city, locality) => `office cleaning services ${locality ? `${locality}, ` : ''}${city}, office cleaning services near me, professional office cleaners ${locality ? `${locality}, ` : ''}${city}, commercial cleaning ${locality ? `${locality}, ` : ''}${city}, affordable office cleaning ${locality ? `${locality}, ` : ''}${city}, office sanitization services ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for office cleaning`,
    },
    'mattress-cleaning': {
        title: (city, locality) => `Mattress Cleaning Services in ${locality ? `${locality}, ` : ''}${city} | Vijay Home Services`,
        description: (city, locality) => `Get expert Mattress Cleaning Services in ${locality ? `${locality}, ` : ''}${city} with Vijay Home Services. Deep clean for dust, stains, and allergens. Book now at 8453748478 for a healthier sleep! Call us on 8453748478  `,
        keywords: (city, locality) => `mattress cleaning services in ${locality ? `${locality}, ` : ''}${city}, best mattress cleaning services near me, mattress cleaning services near me, best mattress cleaning ${locality ? `${locality}, ` : ''}${city}, deep mattress cleaning ${locality ? `${locality}, ` : ''}${city}, mattress sanitization services ${locality ? `${locality}, ` : ''}${city}, affordable mattress cleaning ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for mattress cleaning`,
    },
    'floor-cleaning': {
        title: (city, locality) => `Professional Floor Cleaning in ${locality ? `${locality}, ` : ''}${city}  | Call +91-8453748478`,
        description: (city, locality) => `Get Professional Floor Cleaning Services in ${locality ? `${locality}, ` : ''}${city} at affordable rates. Vijay Home Services guarantees spotless, shining floors for your home or office. Call us on 8453748478 !`,
        keywords: (city, locality) => `floor cleaning service ${locality ? `${locality}, ` : ''}${city}, floor cleaning services near me, professional floor cleaning ${locality ? `${locality}, ` : ''}${city}, affordable floor cleaning services, tile and grout cleaning ${locality ? `${locality}, ` : ''}${city}, carpet floor cleaning ${locality ? `${locality}, ` : ''}${city}, commercial floor cleaning ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for floor cleaning`,
    },
    'mosaic-floor-polishing': {
        title: (city, locality) => `Mosaic Floor Polishing ${locality ? `${locality}, ` : ''}${city} | Call Us: +91-8453748478`,
        description: (city, locality) => `Get the Best Mosaic Floor Polishing in ${locality ? `${locality}, ` : ''}${city} with our expert team. Enjoy a glossy finish and long-lasting shine at affordable rates tailored to your needs. Call us on 8453748478 !`,
        keywords: (city, locality) => `professional mosaic floor polishing ${locality ? `${locality}, ` : ''}${city}, mosaic floor polishing near me, floor polishing near me, mosaic floor restoration ${locality ? `${locality}, ` : ''}${city}, mosaic tile polishing services ${locality ? `${locality}, ` : ''}${city}, affordable mosaic floor polishing ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for mosaic floor polishing`,
    },
    'indian-marble-floor-polishing': {
        title: (city, locality) => `Professional Indian Marble Floor Polishing in ${locality ? `${locality}, ` : ''}${city} `,
        description: (city, locality) => `Get Professional Indian Marble Floor Polishing Services in ${locality ? `${locality}, ` : ''}${city}. Vijay Home Services ensures affordable and long-lasting floor polishing for your home. Call us on 8453748478 !`,
        keywords: (city, locality) => `indian marble floor polishing ${locality ? `${locality}, ` : ''}${city}, best indian marble polishing ${locality ? `${locality}, ` : ''}${city}, floor polishing near me, indian marble polishing near me, indian marble maintenance services ${locality ? `${locality}, ` : ''}${city}, high-quality marble polishing ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for indian marble floor polishing`,
    },
    'italian-marble-floor-polishing': {
        title: (city, locality) => `Professional Italian Floor Marble Polishing in ${locality ? `${locality}, ` : ''}${city} `,
        description: (city, locality) => `Get the Best Italian Marble Polishing Services in ${locality ? `${locality}, ` : ''}${city} at affordable rates. Trust Vijay Home Services for Professional Floor Polishing that enhances your home. Call us on 8453748478 !`,
        keywords: (city, locality) => `italian marble floor polishing ${locality ? `${locality}, ` : ''}${city}, italian marble floor polishing near me, professional italian marble polishing services, italian marble restoration ${locality ? `${locality}, ` : ''}${city}, best italian marble polishing ${locality ? `${locality}, ` : ''}${city}, affordable italian marble polishing ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for italian marble floor polishing`,
    },
    'granite-floor-polishing': {
        title: (city, locality) => `Professional Granite Floor Polishing in ${locality ? `${locality}, ` : ''}${city}`,
        description: (city, locality) => `Get Professional Granite Floor Polishing Services in ${locality ? `${locality}, ` : ''}${city}. Vijay Home Services is the most affordable and trusted company for floor polishing in ${locality}. Call us on 8453748478 !`,
        keywords: (city, locality) => `granite floor polishing ${locality ? `${locality}, ` : ''}${city}, professional granite polishing services, granite floor polishing near me, granite floor restoration ${locality ? `${locality}, ` : ''}${city}, best granite polishing ${locality ? `${locality}, ` : ''}${city}, affordable granite floor polishing services, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for granite floor polishing`,
    },
    'wood-polish': {
        title: (city, locality) => `Top Wood Polishing Services and furniture polishing ${locality ? `${locality}, ` : ''}${city} | Call +91-8453748478`,
        description: (city, locality) => `Get Best Wood Polishing Services in ${locality ? `${locality}, ` : ''}${city}. We specialize in furniture polishing, wood polishing, door polishing, sofa polish, pu polish etc at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `wood polishing services ${locality ? `${locality}, ` : ''}${city}, wood polishing services near me, furniture polishing near me, furniture polishing ${locality ? `${locality}, ` : ''}${city}, wood restoration services ${locality ? `${locality}, ` : ''}${city}, wooden floor polishing ${locality ? `${locality}, ` : ''}${city}, professional wood polishing ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for wood polishing`,
    },
    'terrace-cleaning': {
        title: (city, locality) => `Top Terrace Cleaning, Roof Cleaning and Balcony Cleaning  Services in ${locality ? `${locality}, ` : ''}${city} | Call +91-8453748478`,
        description: (city, locality) => `Get your terrace spotless with our Top Terrace Cleaning Services ${locality ? `${locality}, ` : ''}${city}. Affordable and Reliable Terrace Cleaning in ${city} by Vijay Home Services. Call us on 8453748478 ! `,
        keywords: (city, locality) => `terrace cleaning services ${locality ? `${locality}, ` : ''}${city}, terrace cleaning services near me, roof cleaning ${locality ? `${locality}, ` : ''}${city}, roof cleaning near me, balcony cleaning services ${locality ? `${locality}, ` : ''}${city}, balcony cleaning services near me, professional terrace cleaning ${locality ? `${locality}, ` : ''}${city}, affordable terrace cleaning ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for  terrace cleaning`,
    },
    'tank-and-sump-cleaning': {
        title: (city, locality) => `Tank and Sump Cleaning in ${locality ? `${locality}, ` : ''}${city} | Water Tank Cleaning ${city}`,
        description: (city, locality) => `Book Professional Water Tank Cleaning Services in ${locality ? `${locality}, ` : ''}${city}. Vijay Home Services offers the Best Tank and Sump Cleaning Services in ${city} at Affordable Rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `sump cleaning ${locality ? `${locality}, ` : ''}${city}, tank cleaning near me, Sump cleaning near me, tank cleaning in ${locality ? `${locality}, ` : ''}${city}, water tank cleaning services ${locality ? `${locality}, ` : ''}${city}, tank and sump cleaning ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for tank and sump cleaning`,
    },
    'mini-cleaning-services': {
        title: (city, locality) => `Mini Cleaning Services ${locality ? `${locality}, ` : ''}${city} | Basic Cleaning Services ${city}`,
        description: (city, locality) => `Get Reliable Mini Cleaning Services in ${locality ? `${locality}, ` : ''}${city}. Our Basic Cleaning Services ensure your home is clean and fresh at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `basic cleaning services ${locality ? `${locality}, ` : ''}${city}, basic cleaning services near me, affordable cleaning ${locality ? `${locality}, ` : ''}${city}, residential cleaning services ${locality ? `${locality}, ` : ''}${city}, house cleaning ${locality ? `${locality}, ` : ''}${city}, professional cleaning services ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for mini cleaning services`,
    },
    'rental-painting': {
        title: (city, locality) => `Rental Painting ${locality ? `${locality}, ` : ''}${city} | Painting Services in ${city}`,
        description: (city, locality) => `Get Professional Rental Painting Services in ${locality ? `${locality}, ` : ''}${city} by Vijay Home Services. Get top-quality ${city} Painting, including Rental Painting Services, at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `Rental painting services ${locality ? `${locality}, ` : ''}${city}, rental painting services near me, painting services near me, painting services in ${locality ? `${locality}, ` : ''}${city}, ${city} painting, painters in ${city} for rental painting, home painting services ${locality ? `${locality}, ` : ''}${city},  quality rental painting ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for rental painting`,
    },
    'painting-service': {
        title: (city, locality) => `Painting Services in ${locality ? `${locality}, ` : ''}${city} | Painters Near Me - Flat 50% OFF `,
        description: (city, locality) => `Get Professional Painting Services in ${locality ? `${locality}, ` : ''}${city} by Vijay Home Services. Get top-quality ${city} Painting Services, at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `painting services ${locality ? `${locality}, ` : ''}${city}, painting services near me, painting services in ${locality ? `${locality}, ` : ''}${city}, ${city} painting, painters in ${city}, home painting services ${locality ? `${locality}, ` : ''}${city}, rental painting ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for painting services`,
    },

    'interior-painting': {
        title: (city, locality) => `Interior Painting ${locality ? `${locality}, ` : ''}${city} | Best Painting Services in ${city}`,
        description: (city, locality) => `Get Interior Top Painting Services in ${locality ? `${locality}, ` : ''}${city}, offering Best Interior Home Painting Services. Get the Best ${city} Interior Painting Services at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `Interior painting services ${locality ? `${locality}, ` : ''}${city}, painting services in ${locality ? `${locality}, ` : ''}${city}, ${city}, Interior painters in ${city}, Interior home painting services ${locality ? `${locality}, ` : ''}${city}, Good  Interior painting services ${locality ? `${locality}, ` : ''}${city}, Interior painting near me, Best Interior painting near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for interior painting`,
    },
    'exterior-painting': {
        title: (city, locality) => `Exterior Painting ${locality ? `${locality}, ` : ''}${city} | Painting Services in ${city}`,
        description: (city, locality) => `Top Exterior Painting Services in ${locality ? `${locality}, ` : ''}${city}. Get Professional Exterior Painting Services for your home or business at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `exterior painting services ${locality ? `${locality}, ` : ''}${city}, exterior painting services near me, top exterior painting services in ${locality ? `${locality}, ` : ''}${city}, ${city}, exterior painters in ${city}, exterior home painting services ${locality ? `${locality}, ` : ''}${city}, painting services ${locality ? `${locality}, ` : ''}${city}, exterior painting services near me, best exterior painting services near me, painting services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for exterior painting`,
    },
    'interior-texture': {
        title: (city, locality) => `Interior Texture Painting Services in ${locality ? `${locality}, ` : ''}${city} | ${city} Painters`,
        description: (city, locality) => `Top Interior Texture Painting Services in ${locality ? `${locality}, ` : ''}${city}. Get Professional ${city} Painting Services for your home or business at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => ` Interior and Exterior texture painting services ${locality ? `${locality}, ` : ''}${city}, Interior texture painting services near me, Interior texture painting services in ${locality ? `${locality}, ` : ''}${city}, ${city}, texture painters in ${city},texture painters near me, home painting services ${locality ? `${locality}, ` : ''}${city}, interior texture painting services ${locality ? `${locality}, ` : ''}${city}, texture painting near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for interior texture painting`,
    },
    'exterior-texture': {
        title: (city, locality) => `Exterior Texture Painting Services in ${locality ? `${locality}, ` : ''}${city} | ${city} Painters`,
        description: (city, locality) => `Top Exterior Texture Painting Services in ${locality ? `${locality}, ` : ''}${city}. Get Professional ${city} Painting Services for your home or business at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => ` Interior and Exterior texture painting services ${locality ? `${locality}, ` : ''}${city}, painting services in ${locality ? `${locality}, ` : ''}${city}, ${city} painting, painters in ${city}, home painting services ${locality ? `${locality}, ` : ''}${city}, exterior texture painting services ${locality ? `${locality}, ` : ''}${city}, texture painting near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for exterior texture painting`,
    },
    'waterproofing': {
        title: (city, locality) => `Waterproofing Services ${locality ? `${locality}, ` : ''}${city} | Waterproofing Contractors `,
        description: (city, locality) => `Trusted Waterproofing Contractors ${locality ? `${locality}, ` : ''}${city} offering reliable Waterproofing Services in ${city}. Call us on 8453748478 to get Expert Solutions from a Top Waterproofing Company.`,
        keywords: (city, locality) => `waterproofing contractors ${locality ? `${locality}, ` : ''}${city}, waterproofing services in ${locality ? `${locality}, ` : ''}${city}, waterproofing ${locality ? `${locality}, ` : ''}${city}, waterproofing company in ${city}, waterproofing service near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for waterproofing`,
    },
    'wallpaper': {
        title: (city, locality) => `Wallpaper Instalation Services ${locality ? `${locality}, ` : ''}${city} | Wallpaper ${city}`,
        description: (city, locality) => `Book Professional Wallpaper Installation Services ${locality ? `${locality}, ` : ''}${city} for stunning interiors. Get Best Wallpaper options and expert installation at affordable rates in ${city}. Call us on 8453748478 !`,
        keywords: (city, locality) => `wallpaper installation ${locality ? `${locality}, ` : ''}${city}, wallpaper ${locality ? `${locality}, ` : ''}${city}, cost of wallpaper installation, installing wallpaper cost, wallpaper installtion cost, price of wallpaper installtion, wallpaper installation near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for wallpaper installation`,
    },
    'grouting-services': {
        title: (city, locality) => `Grouting Services in ${locality ? `${locality}, ` : ''}${city} | Expert Tile & Grout Repair`,
        description: (city, locality) => `Book Professional Grouting Services in ${locality ? `${locality}, ` : ''}${city} to restore the beauty of your tile surfaces. Get Best Tile and Grout Repair in ${city} at affordable rates. Call us on 8453748478 !`,
        keywords: (city, locality) => `epoxy grouting in ${locality ? `${locality}, ` : ''}${city}, grouting services in ${locality ? `${locality}, ` : ''}${city}, tile and grout repair ${locality ? `${locality}, ` : ''}${city}, grouting services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for grouting services`,
    },
    'vacant-flat-painting': {
        title: (city, locality) => `Vacant Flat Painting Services in ${locality ? `${locality}, ` : ''}${city} | Call +91-8453748478`,
        description: (city, locality) => `Transform your vacant flat or home with quality Painting Services in ${locality ? `${locality}, ` : ''}${city}. Enjoy expert painters, free rescheduling, and the best prices for Vacant Flat Painting Services. Call us on 8453748478 !`,
        keywords: (city, locality) => `vacant flat painting services ${locality ? `${locality}, ` : ''}${city}, vacant flat painting services near me, painting services in ${locality ? `${locality}, ` : ''}${city}, ${city}, vacant flat painters in ${city}, home painting services ${locality ? `${locality}, ` : ''}${city}, vacant flat painting ${locality ? `${locality}, ` : ''}${city}, empty flat painting ${locality ? `${locality}, ` : ''}${city}, empty flat painting services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for vacant flat painting`,
    },
    '1-day-painting': {
        title: (city, locality) => `Top One Day Home Painting Contractors in ${locality ? `${locality}, ` : ''}${city}`,
        description: (city, locality) => `Vijay Home Services is your one-stop destination for expert one day Painting Services in ${locality ? `${locality}, ` : ''}${city} at your convenience. Call us on 8453748478 to get Popular One Day Home Painting in ${city}.`,
        keywords: (city, locality) => `one day painting services ${locality ? `${locality}, ` : ''}${city}, painting services in ${locality ? `${locality}, ` : ''}${city}, ${city}, painters in ${city}, one day home painting services ${locality ? `${locality}, ` : ''}${city}, one day painting services ${locality ? `${locality}, ` : ''}${city} near me, one day painting near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for one day painting`,
    },
    'general-pest-control': {
        title: (city, locality) => `Top General Pest Control in ${locality ? `${locality}, ` : ''}${city} | Call +91-8453748478 `,
        description: (city, locality) => `Get Best General Pest Control in ${locality ? `${locality}, ` : ''}${city} with safe and eco-friendly solutions. Choose Best Pest Control in ${city} for a chemical-free way to protect your home or office. Call us on 8453748478 !`,
        keywords: (city, locality) => `general pest control in ${locality ? `${locality}, ` : ''}${city}, pest control services in ${locality ? `${locality}, ` : ''}${city}, general pest control services near me, pest control services ${locality ? `${locality}, ` : ''}${city}, , top 3 pest control services in ${city}, top three pest control services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for general pest control`,
    },
    'pest-control': {
        title: (city, locality) => `Top Pest Control in ${locality ? `${locality}, ` : ''}${city} | Call +91-8453748478 `,
        description: (city, locality) => `Get Best Pest Control in ${locality ? `${locality}, ` : ''}${city} with safe and eco-friendly solutions. Choose Best Pest Control in ${city} for a chemical-free way to protect your home or office. Call us on 8453748478 !`,
        keywords: (city, locality) => `pest control in ${locality ? `${locality}, ` : ''}${city}, pest control near me, pest control services in ${locality ? `${locality}, ` : ''}${city}, pest control services ${locality ? `${locality}, ` : ''}${city}, , top three pest control services in ${city}, top 3 pest control services near me, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for pest control`,
    },
    'cockroach-control': {
        title: (city, locality) => `Top Cockroach Pest Control Services in ${locality ? `${locality}, ` : ''}${city} `,
        description: (city, locality) => `Vijay Home Services offers Top Cockroach Pest Control in ${locality ? `${locality}, ` : ''}${city} at affordable prices. Call us on 8453748478 to book Cockroach Control to eliminate pests effectively and safely.`,
        keywords: (city, locality) => `cockroach pest control ${locality ? `${locality}, ` : ''}${city}, cockroach pest control near me, cockroach control ${locality ? `${locality}, ` : ''}${city}, cockroach control services ${locality ? `${locality}, ` : ''}${city}, cockroach control services in ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for cockroach control`,
    },
    'mosquitoes-control': {
        title: (city, locality) => `Mosquito Control ${locality ? `${locality}, ` : ''}${city} | Mosquito Fogging`,
        description: (city, locality) => `Get Best Mosquito Control in ${locality ? `${locality}, ` : ''}${city} with reliable Mosquito Fogging Services. Protect your home from mosquitoes with effective and affordable solutions. Call us on 8453748478 !`,
        keywords: (city, locality) => `mosquito control services ${locality ? `${locality}, ` : ''}${city}, mosquito control services near me, mosquito fogging ${locality ? `${locality}, ` : ''}${city}, best mosquito exterminators in ${city}, affordable mosquito pest control ${locality ? `${locality}, ` : ''}${city}, residential mosquito control ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for mosquito control`,
    },
    'termite-control': {
        title: (city, locality) => `Termite Control ${locality ? `${locality}, ` : ''}${city} | Termite Treatment`,
        description: (city, locality) => `Get Termite control in ${locality ? `${locality}, ` : ''}${city} to protect your property from infestations. Get Affordable Anti-Termite Treatment for both pre-construction and post-construction. Call us on 8453748478 !`,
        keywords: (city, locality) => `termite treatment ${locality ? `${locality}, ` : ''}${city}, termite treatment near me, termite control near me, termite control services ${locality ? `${locality}, ` : ''}${city}, affordable termite extermination ${locality ? `${locality}, ` : ''}${city}, pre-construction termite treatment ${locality ? `${locality}, ` : ''}${city}, post-construction anti-termite services ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for termite control`,
    },
    'woodborer-control': {
        title: (city, locality) => `Wood Borer Treatment In ${locality ? `${locality}, ` : ''}${city} | Wood Borer Control`,
        description: (city, locality) => `Get effective Wood Borer Treatment in ${locality ? `${locality}, ` : ''}${city} to protect your wooden structures. Our expert Wood Borer Control Services ensure safe and reliable solutions. Call us on 8453748478 !`,
        keywords: (city, locality) => `wood borer treatment ${locality ? `${locality}, ` : ''}${city}, wood borer treatment near me, wood borer control ${locality ? `${locality}, ` : ''}${city}, wood borer control near me, wood borer pest control ${locality ? `${locality}, ` : ''}${city}, wood borer extermination ${locality ? `${locality}, ` : ''}${city}, wood borer prevention services ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for wood borer control, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for wood borer treatment`,
    },
    'commercial-pest-control': {
        title: (city, locality) => `Commercial Pest Control ${locality ? `${locality}, ` : ''}${city} | Industrial Pest Control`,
        description: (city, locality) => `Book the Top Commercial Pest Control Services in ${locality ? `${locality}, ` : ''}${city} for businesses. Our Affordable Pest Management Services ensure a pest-free environment in ${city}. Call us on 8453748478 !`,
        keywords: (city, locality) => `commercial pest control ${locality ? `${locality}, ` : ''}${city}, pest control services for businesses ${locality ? `${locality}, ` : ''}${city}, industrial pest control ${locality ? `${locality}, ` : ''}${city}, commercial pest management ${locality ? `${locality}, ` : ''}${city}, affordable commercial pest control ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for commercial pest control`,
    },
    'bedbugs-control': {
        title: (city, locality) => `Bed Bug Control ${locality ? `${locality}, ` : ''}${city} | Bed Bug Pest Control ${city}`,
        description: (city, locality) => `Get effective Bed Bug Control in ${locality ? `${locality}, ` : ''}${city} with our expert Pest Control Services. Say goodbye to bed bugs with safe and reliable treatment solutions. Call us on 8453748478 !`,
        keywords: (city, locality) => `pest control bed bugs ${locality ? `${locality}, ` : ''}${city}, bed bugs control ${locality ? `${locality}, ` : ''}${city}, bed bugs control near me, bed bug control ${locality ? `${locality}, ` : ''}${city}, bed bug treatment ${locality ? `${locality}, ` : ''}${city}, bed bug control in my city, beg bug control in my area, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for bed bug control`,
    },
    'washing-machine-repairing': {
        title: (city, locality) => `Washing Machine Repair Services in ${locality ? `${locality}, ` : ''}${city} | Expert Repairs at ₹160`,
        description: (city, locality) => `Need washing machine repair in ${locality ? `${locality}, ` : ''}${city}? Get doorstep service within 60 minutes for LG, Samsung, and other brands. Repairs start at just ₹160. Call us on 8453748478 to get expert assistance!`,
        keywords: (city, locality) => `Washing Machine Repair ${locality ? `${locality}, ` : ''}${city}, Washing Machine Repair near me,  Doorstep Washing Machine Repair ${locality ? `${locality}, ` : ''}${city}, Affordable Washing Machine Repair ${locality ? `${locality}, ` : ''}${city}, Samsung Washing Machine Repair ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for washing machine repair`,
    },
    'refrigerator-repairing': {
        title: (city, locality) => `Refrigerator Repair Services in ${locality ? `${locality}, ` : ''}${city} | Fast Repairs Starting ₹160`,
        description: (city, locality) => `Need refrigerator repair in ${locality ? `${locality}, ` : ''}${city}? Get expert doorstep service for all brands, including LG and Samsung. Reliable fridge repair near you starting at ₹160. Call us on 8453748478 today!`,
        keywords: (city, locality) => `Refrigerator Repair ${locality ? `${locality}, ` : ''}${city}, Refrigerator Repair near me, Fridge Repair Near Me ${locality ? `${locality}, ` : ''}${city}, Top Refrigerator Repair Services ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for fridge or refrigerator repair`
    },
    'geyser-repairing': {
        title: (city, locality) => `Geyser Repair Services in ${locality ? `${locality}, ` : ''}${city} | Same-Day Service ₹249`,
        description: (city, locality) => `Looking for reliable geyser repair in ${locality ? `${locality}, ` : ''}${city}? Get same-day repair starting at ₹249. Trusted experts for Bajaj and electric geyser services near you. Call us on 8453748478 today!`,
        keywords: (city, locality) => `Geyser Repair ${locality ? `${locality}, ` : ''}${city}, Geyser Repair near me,  Bajaj Geyser Repair ${locality ? `${locality}, ` : ''}${city}, Electric Geyser Repair Near Me ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for geyser repair`
    },
    'window-ac-service': {
        title: (city, locality) => `Window AC Services in ${locality ? `${locality}, ` : ''}${city} | Trusted Experts Starts at Rs 299 onwards`,
        description: (city, locality) => `Professional Window AC repair, cleaning, and gas refilling services in ${locality ? `${locality}, ` : ''}${city}. Same-day doorstep service by skilled technicians. Call us on 8453748478!`,
        keywords: (city, locality) => `Window AC Repair ${locality ? `${locality}, ` : ''}${city},Window AC Repair near me, Window AC Cleaning ${locality ? `${locality}, ` : ''}${city}, Window AC Gas Refill ${locality ? `${locality}, ` : ''}${city}, best AC technicians near me, LG AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Blue star Repair services in ${locality ? `${locality}, ` : ''}${city}, Voltas AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Hitachi AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Panasonic AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for AC repair`
    },
    'split-ac-service': {
        title: (city, locality) => `Split AC Services in ${locality ? `${locality}, ` : ''}${city} | Trusted Cleaning & Repairs`,
        description: (city, locality) => `Get professional Split AC cleaning, repair, and gas refilling services in ${locality ? `${locality}, ` : ''}${city}. Same-day service by experienced technicians. Call us on 8453748478 now!`,
        keywords: (city, locality) => `Split AC Repair ${locality ? `${locality}, ` : ''}${city}, Split AC Repair near me, ac repair and cleaning near me, Split AC Cleaning ${locality ? `${locality}, ` : ''}${city}, Split AC Gas Refilling ${locality ? `${locality}, ` : ''}${city}, best AC technicians near me, LG AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Blue star Repair services in ${locality ? `${locality}, ` : ''}${city}, Voltas AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Hitachi AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Panasonic AC Repair services in ${locality ? `${locality}, ` : ''}${city}, Vijay Home Services in ${locality ? `${locality}, ` : ''}${city} for AC Repair`
    },

    // ... add templates for other services
};


function capitalizeFirstLetter(string) {
  console.log("capitalizeFirstLetter input:", string);
  if (typeof string !== "string" || string.length === 0) {
      return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getSeoMetadata(subcategorySlug, locationSlug) { // Modified function signature
  console.log("getSeoMetadata inputs:", { subcategorySlug, locationSlug }); // Modified log
  const serviceTemplateKey = subcategorySlug.toLowerCase().replace(/-/g, '-');
  const template = seoTemplates[serviceTemplateKey];
  let cityName = null;
  let localityName = null;

  // Check if locationSlug is a city or locality
  let isCity = false;
  let isLocality = false;
  let parentCityForLocality = null;

  for (const city in localities) {
      if (city === locationSlug) {
          isCity = true;
          cityName = capitalizeFirstLetter(locationSlug);
          break; // Found as city, no need to check localities
      }
      if (localities[city].includes(locationSlug)) {
          isLocality = true;
          parentCityForLocality = city;
          cityName = capitalizeFirstLetter(city); // Parent city name
          localityName = capitalizeFirstLetter(locationSlug); // Locality name
          break; // Found as locality, break inner loop and outer loop
      }
  }

  if (!isCity && !isLocality) {
      // Handle case where locationSlug is neither city nor locality (optional - for safety)
      cityName = capitalizeFirstLetter(locationSlug); // Default to treat as city if not found in localities.json
      console.warn(`Location slug "${locationSlug}" not found in localities.json. Treating as city.`);
  }


  if (template) {
      return {
          meta: {
              title: template.title(cityName, localityName),
              description: template.description(cityName, localityName),
              keywords: template.keywords(cityName, localityName),
          }
      };
  }

  // Default metadata if no template is found (optional)
  return {
      meta: {
          title: "Expert Home Services Up to 60% OFF - Vijay Home Services",
          description: "Experience top-notch home services cleaning, painting, pest control,etc. Lowest Price in Market | ISO Certified Company. Trained Professionals | No Sub Contract. 100% Satisfaction or FREE Rework*.",
          keywords: "home services, cleaning services, pest control, painting services, home repairs, maintenance services, eco-friendly cleaning, affordable home care, reliable home solutions, expert home services, one-stop home services, Vijay Home Services, professional home care, quality home services.",
      }
  };
}


export {
  getSeoMetadata,
};